import React from "react"
import News from "../../blocks/news"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "osipov_contract" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>5 Февраля 2020 Catenaccio успешно представило интересы футболиста <Link to="/players/osipov">Артёма Осипова</Link> в судопроизводстве касательно нарушения контракта перед Палатой по Разрешению Споров в ФИФА против футбольного клуба из Литвы. Catenaccio убедило Палату признать недействительным определённые условия трудового контракта, доказав необходимость применения теста «потестативности». Согласившись с аргументом Catenaccio для решения спора, Палата разрешила Артёму взыскать в судебном порядке его полную зарплату по контракту и присудила денежную компенсацию.</p>
        </div>
      </div>
    )
  }
}